import React from 'react'
import { Box, Button, Heading, Text, Anchor } from 'grommet'

import AppLayout from '../components/app-layout'

const RegisterPage = () => (
  <AppLayout page="register">
    <Box
      style={{ maxWidth: '48rem', marginLeft: 'auto', marginRight: 'auto' }}
      fill={true}
      pad='xlarge'
    >
      <Heading color='accent-2' textAlign='center'>
        register your kit
      </Heading>
      <Text
        margin={{ vertical: 'medium' }}
        textAlign='center'
      >
        To get started, log in or create an account.
        <br />
        Have your Gini Test Kit barcode ready.
      </Text>
      <Box
        direction='row-responsive'
        align='end'
        justify='between'
        gap='large'
        margin={{ vertical: 'large' }}
      >
        <Box
          basis='1/2'
          justify='center'
          align='center'
        >
          <Text
            margin={{ vertical: 'medium' }}
            color='brand'
          >
            I have an account
          </Text>
          <Button
            href='/signin'
            label={'log in'}
            color='accent-1'
            primary
          />
        </Box>
        <Box
          basis='1/2'
          justify='center'
          align='center'
        >
          <Text
            margin={{ vertical: 'medium' }}
            color='brand'
          >
            Create an account
          </Text>
          <Button
            href='/signup'
            label={'sign up'}
            color='accent-1'
            primary
          />
        </Box>
      </Box>
      <Box
        direction='row'
        align='end'
        justify='center'
        margin={{ vertical: 'large', horizontal: 'medium' }}
      >
        <Anchor
          href={'https://ginihealth.com/instructions'}
          style={{ color:'accent-1'}}
          target='_blank'
        >
          <Text
            style={{ textDecoration: 'underline' }}
            color='accent-2'
            textAlign='center'
          >
            View Instructions
          </Text>
        </Anchor>
      </Box>
    </Box>
  </AppLayout>
)

export default RegisterPage
